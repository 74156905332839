<!-- @format -->

<template>
	<div class="guild">
		<v-container class="mx-auto py-0">
			<p class="text-h4 text-center font-weight-bold text-uppercase">Hướng Dẫn Chia Sẻ Tài Khoản</p>

			<v-card class="pa-3">
				<p class="text-h6">
					👉 Bước 1: Tạo tài khoản
					<router-link :to="{ name: 'Register' }">tại đây</router-link>
					. Hoặc nếu bạn đã có tài khoản, vui lòng đăng nhập
					<router-link :to="{ name: 'Login' }">tại đây</router-link>
					.
				</p>

				<p class="text-h6">
					👉 Bước 2: Chọn sản phẩm bạn muốn tham gia
					<router-link :to="{ name: 'Home' }">tại đây</router-link>
				</p>

				<p class="text-h6">
					👉 Bước 3: Sau khi chọn và xem sản phẩm. Bạn có thể tham gia bằng nút
					<span class="font-weight-bold">Tham Gia Ngay</span>
					bên tay phải
					<v-img src="../../assets/img/guild1.png" width="600" class="mx-auto mt-2"></v-img>
				</p>

				<p class="text-h6">
					👉 Bước 4.1: Nếu tài khoản không tự nhập.
					<v-img src="../../assets/img/guild41.png" width="600" class="mx-auto mt-2"></v-img>
				</p>

				<p class="text-body-1 ml-10 ">▶ Bạn chọn số tháng muốn tham gia từ ô số 1.</p>
				<p class="text-body-1 ml-10">▶ Nếu có mã giảm giá, vui lòng điền vào ô số 2 và ấn nút Kiểm Tra.</p>
				<p class="text-body-1 ml-10">▶ Nếu muốn sử dụng tài khoản tích luỹ. Bãn hãy tích vào ô số 3.</p>
				<p class="text-subtitle-1 ml-10">
					⚠ Lưu ý: Với mỗi hoá đơn bạn sẽ được tích luỹ 3% vào tài khoản tích luỹ. Bạn có thể xem số dư tài khoản tích luỹ ở
					<router-link :to="{ name: 'Profile' }">trang cá nhân</router-link>
					.
				</p>
				<p class="text-body-1 ml-10">▶ Nhập số lượng profile ở số 4. Số lượng tối thiểu là 1.</p>

				<p class="text-h6 mt-5">
					👉 Bước 4.2: Nếu tài khoản tự nhập.
					<v-img src="../../assets/img/guild42.png" width="600" class="mx-auto mt-2"></v-img>
				</p>

				<p class="text-body-1 ml-10">▶ Ô số 1,2,3 giống với bước 4.1 .</p>
				<p class="text-body-1 ml-10">▶ Nhập tên đăng nhập/email của tài khoản bạn muốn tham gia vào ô số 4 và mật khẩu vào ô số 5.</p>

				<p class="text-h6 mt-5">
					👉 Bước 5: Sau khi bấm tham gia ngay. Nếu tham gia thành công sẽ hiện ra một popup gửi tài khoản đến bạn.
					<br />
					
					<span class="text-subtitle-1 ml-10">
						⚠ Lưu ý: Bạn có thể xem lại tại
						<router-link :to="{ name: 'OrderHistory' }">lịch sử tham gia</router-link>
						.
					</span>
					
					<v-img src="../../assets/img/guild5.png" width="600" class="mx-auto mt-3"></v-img>
				</p>
			</v-card>
		</v-container>
	</div>
</template>

<script>
	import { Vue, Component } from 'vue-property-decorator'

	@Component
	export default class Guild extends Vue {}
</script>

<style></style>
